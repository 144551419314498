
// export const MAIN_URL: string = "http://34.111.235.57/news-english-api/";
export const MAIN_URL: string = "https://apienglish.newsfirst.lk/";
// export const MAIN_URL: string = "http://localhost:3000/";
// export const MAIN_URL: string = "http://34.100.175.63:3000/";


// export const FRONT_URL: string = "http://34.100.175.63:4000";
export const FRONT_URL: string = "https://english.newsfirst.lk";
// export const FRONT_URL: string = "http://localhost:4200/";


export const TITLE: string = "Newsfirst.lk - Sri Lanka | Latest Breaking News and Top Stories | Sirasa News"
export const TITLE_BUSINESS: string = "Business News | Sri Lanka | Financial News | Inflation | Budget"
export const TITLE_SPORT: string = "Sports News | Sri Lanka | Cricket | Rugby | Football | Latest News"
export const TITLE_FEATURED: string = "Featured Archives | Sri Lanka News - Newsfirst"
export const TITLE_LOCAL: string = "Local News | Sri Lanka | News 1st | Top stories | Breaking News | Latest News"
export const TITLE_LATEST: string = "Sri Lanka Latest News Articles Sri Lanka News - Newsfirst"
export const TITLE_WORLD: string = "World News | International News | Top stories | Breaking News | Latest News"
export const TITLE_TIMELINE: string = " | Sri Lanka News - Newsfirst"


export const DESCIPTION_BASE = "Get the latest breaking news and top stories from Sri Lanka, the latest political news, sports news, weather updates, exam results, business news, entertainment news, world news and much more from News 1st, Sri Lanka's leading news network."
export const DESCIPTION_BASE_LOCAL = "Sri Lanka News: Get the latest local news, breaking news and top stories around the country. Keep yourself updated with the News 1st."
export const DESCIPTION_BASE_SPORT = "Sri Lanka News: Cricket News. Access all the latest sports coverage, live reports, analysis and comments. View scores and videos online."
export const DESCIPTION_BASE_WORLD = "World News: Get the latest breaking news headlines and top stories from around the world at newsfirst, Sri Lanka’ premier news reporter."
export const DESCIPTION_BASE_BUSINESS = "Sri Lanka News: Get the latest breaking financial, business and economic news and top stories from our business news page."
export const DESCIPTION_BASE_TIMELINE = "Get the latest breaking news and top stories from Sri Lanka, the latest political news, sports news, weather updates, exam results, business news, entertainment news, world news and much more from News 1st, Sri Lanka&#039;s leading news network."
