import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [

  {
    path: ':year/:month/:day/:name',

    loadChildren: () => import('./util/news/news.module').then(m => m.NewsModule),
  },

  // {
  //   path: ':name',

  //   loadChildren: () => import('./util/news/news.module').then(m => m.NewsModule),
  // },

  {
    path: '',
    loadChildren: () => import('./util/home/home.module').then(m => m.HomeModule),
  },

  // { path: '', redirectTo: '/smiley', pathMatch: 'full' },
  {
    path: 'home',
    loadChildren: () => import('./util/home/home.module').then(m => m.HomeModule),

  },
  {
    path: 'tag/:tag',
    loadChildren: () => import('./util/tags/tags.module').then(m => m.TagsModule),
  },
  {
    path: 'tag',
    loadChildren: () => import('./util/tags/tags.module').then(m => m.TagsModule),
  },
  {
    path: 'latest',
    loadChildren: () => import('./util/latest/latest.module').then(m => m.LatestModule),

  },
  {
    path: 'featured',
    loadChildren: () => import('./util/featured/featured.module').then(m => m.FeaturedModule),

  },
  {
    path: 'local',
    loadChildren: () => import('./util/local/local.module').then(m => m.LocalModule),

  },
  {
    path: 'sports',
    loadChildren: () => import('./util/sport/sport.module').then(m => m.SportModule),

  },
  {
    path: 'business',
    loadChildren: () => import('./util/business/business.module').then(m => m.BusinessModule),

  },
  {
    path: 'corporate-brief',
    loadChildren: () => import('./util/corporate-brief/corporate-brief.module').then(m => m.CorporateBriefModule),

  },
  {
    path: 'world',
    loadChildren: () => import('./util/world/world.module').then(m => m.WorldModule),

  },
  {
    path: 'election-manifesto-2024',
    loadChildren: () => import('./util/doc/doc.module' ).then(m => m.DocModule),

  },
  {
    path: 'news/:id',
    loadChildren: () => import('./util/news/news.module').then(m => m.NewsModule),

  },
  {
    path: 'search',
    loadChildren: () => import('./util/search/search.module').then(m => m.SearchModule),

  },
  {
    path: ':year/:month/:day',
    loadChildren: () => import('./util/timeline/timeline.module').then(m => m.TimelineModule),

  },
  {
    path: ':year/:month',
    loadChildren: () => import('./util/timeline/timeline.module').then(m => m.TimelineModule),

  },
  {
    path: ':year',
    loadChildren: () => import('./util/timeline/timeline.module').then(m => m.TimelineModule),

  },



]

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false, initialNavigation: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
