import {
  Component,
  HostListener,
  OnInit,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Location, DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
import { DESCIPTION_BASE, TITLE } from './constant/app-constant';
import { Renderer2 } from '@angular/core';
import { FRONT_URL } from './constant/app-constant';
import { PopUpComponent } from './util/pop-up/pop-up.component';
import { MatDialog } from '@angular/material/dialog';
interface Food {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  selectHome = true;
  routerPath = 'home';
  showFiller = false;
  value: number = 0;
  selectedLang = 'eng';
  selectedValue!: string;
  base_url = FRONT_URL;
  constructor(
    private router: Router,
    private renderer2: Renderer2,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private document: Document,
    public dialog: MatDialog
  ) {
    // @ts-ignore
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        if (!(event.url === '/business' || event.url === '/corporate-brief')) {
          this.selectedValue = 'business';
        }
        this.routerPath = event.url;
        if (this.routerPath === '/') {
        } else {
          this.test(this.routerPath);
        }
      }
    });
  }

  ngOnInit() {
    const s = this.renderer2.createElement('script');
    s.type = 'text/javascript';
    s.src = 'https://tags.adstudio.cloud/newsfirst.js';
    this.renderer2.appendChild(this.document.body, s);

    if (typeof window !== 'undefined' && sessionStorage) {

      const savedValue = sessionStorage.getItem('selectedValue');
      this.selectedValue = savedValue ? savedValue : 'business';

      if (!savedValue) {
        sessionStorage.setItem('selectedValue', this.selectedValue);
      }
    }
  }

  // const s2 = this.renderer2.createElement('script');
  // s2.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5432073672371728';
  // s2.crossorigin = "anonymous";
  // this.renderer2.appendChild(this.document.body, s2);

  // const s3 = this.renderer2.createElement('script');
  // s3.innerHTML = '(adsbygoogle = window.adsbygoogle || []).push({});';
  // this.renderer2.appendChild(this.document.body, s3);

  // this.open()

  // ngAfterContentInit(): void {
  //   if (isPlatformBrowser(this.platformId)) {
  //     this.dialog.open(PopUpComponent);
  //   }
  // }

  ChangingValue(val: any) {
    this.selectedLang = val.value;

    // Save value to session storage
    this.selectedValue = val.value;
    if (typeof window !== 'undefined' && sessionStorage) {
      sessionStorage.setItem('selectedValue', this.selectedValue);
    }

  }



  test(type?: string, drawer?: any) {
    if (type != '/home') {
      this.selectHome = false;
    } else {
      this.selectHome = true;
    }
    this.moveUp();
    drawer?.toggle();
  }

  moveUp() {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }

  clickme(value: string, drawer?: any) {
    drawer?.toggle();
    this.router.navigateByUrl('/search', { state: { value: value } });
  }
}
